<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <HeroSection :modulePage="modulePage" v-if="moduleRole('hero')" />
          <b-card no-body>
            <template #header>
              <h5 class="card-title">Contact Information</h5>
            </template>
            <validation-observer ref="VForm" v-slot="{handleSubmit}">
              <b-form @submit.prevent="handleSubmit(submitContent)">
                <b-card-body>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group>
                        <template #label>Title (ID) <span class="text-danger">*</span></template>
                        <b-form-input v-model="row.asc_title_id"></b-form-input>
                        <VValidate name="Title (ID)" v-model="row.asc_title_id" rules="required|min:3|max:160"/>
                      </b-form-group>
                      <b-form-group>
                        <template #label>Description (ID)</template>
                        <b-form-textarea v-model="row.asc_sub_title_id"></b-form-textarea>
                        <VValidate name="Description (ID)" v-model="row.asc_sub_title_id" rules="min:3"/>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group>
                        <template #label>Title (EN) <span class="text-danger">*</span></template>
                        <b-form-input v-model="row.asc_title_en"></b-form-input>
                        <VValidate name="Title (EN)" v-model="row.asc_title_en" rules="required|min:3|max:160"/>
                      </b-form-group>
                      <b-form-group>
                        <template #label>Description (EN)</template>
                        <b-form-textarea v-model="row.asc_sub_title_en"></b-form-textarea>
                        <VValidate name="Description (EN)" v-model="row.asc_sub_title_en" rules="min:3"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-row class="g-0">
                  <b-col md="6">
                    <b-card-body class="border-top">
                      <b-row class="gx-2">
                        <b-col md=6>
                          <b-form-group>
                            <template #label>Telephone <span class="text-danger">*</span></template>
                            <b-form-input v-model="row.asc_data.phone" placeholder="e.g. 812 5150 4148" />
                            <VValidate name="Telephone" v-model="row.asc_data.phone" rules="required|min:6|max:15" />
                          </b-form-group>
                        </b-col>
                        <b-col md=6>
                          <b-form-group>
                            <template #label>Email <span class="text-danger">*</span></template>
                            <b-input-group>
                              <template #prepend>
                                <span class="input-group-text"><i class="far fa-envelope"></i></span>
                              </template>
                              <b-form-input v-model="row.asc_data.email" placeholder="e.g. hello@lingkar9.com" />
                            </b-input-group>
                            <VValidate name="Email" v-model="row.asc_data.email" rules="required|email" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="12">
                          <b-form-group>
                            <template #label>Location <strong class="text-danger">*</strong></template>
                            <b-form-textarea v-model="row.asc_data.location" placeholder="e.g. Jl. Cemara II No. 15c Pamulang" rows="4" />
                            <VValidate name="Location" v-model="row.asc_data.location" rules="required" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="12">
                          <b-form-group>
                            <template #label>Location Workshop <strong class="text-danger">*</strong></template>
                            <b-form-textarea v-model="row.asc_data.location_workshop" placeholder="e.g. Jl. Cemara II No. 15c Pamulang" rows="4" />
                            <VValidate name="Location Workshop" v-model="row.asc_data.location_workshop" rules="required" />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-col>
                  <b-col md="6">
                    <b-card-body class="border-left border-top">
                      <b-row class="gx-2">
                        <b-col lg="6">
                          <b-form-group>
                            <template #label>Facebook</template>
                            <b-input-group>
                              <template #prepend>
                                <span class="input-group-text"><i class="fab fa-facebook"></i></span>
                              </template>
                              <b-form-input v-model="row.asc_data.facebook" placeholder="e.g. https://www.facebook.com/lingkar9titianmedia" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group>
                            <template #label>Twitter</template>
                            <b-input-group>
                              <template #prepend>
                                <span class="input-group-text"><i class="fab fa-twitter"></i></span>
                              </template>
                              <b-form-input v-model="row.asc_data.twitter" placeholder="e.g. https://twitter.com/lingkar9id" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group>
                            <template #label>Instagram</template>
                            <b-input-group>
                              <template #prepend>
                                <span class="input-group-text"><i class="fab fa-instagram"></i></span>
                              </template>
                              <b-form-input v-model="row.asc_data.instagram" placeholder="e.g. https://www.instagram.com/lingkar9id/" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group>
                            <template #label>Youtube</template>
                            <b-input-group>
                              <template #prepend>
                                <span class="input-group-text"><i class="fab fa-youtube"></i></span>
                              </template>
                              <b-form-input v-model="row.asc_data.youtube" placeholder="youtube" />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group>
                            <template #label>Tiktok</template>
                            <b-form-input v-model="row.asc_data.tiktok" placeholder="tiktok" />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-col>
                </b-row>

              </b-form>
            </validation-observer>
            <b-card-footer>
							<div class="text-right">
								<button @click="submitContent" class="btn btn-rounded btn-success">Save Changes</button>
							</div>
						</b-card-footer>
          </b-card>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('seo')">
          <SEOSection />
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import SEOSection from '@/components/SEOSection'
import HeroSection from '@/components/HeroSection'

export default {
  name: 'BoAboutUs',
  extends: GlobalVue,
  components: {
    HeroSection,
    SEOSection,
  },
  mounted() {
    this.apiGet()
  },
  data() {
    return {     
      productCatOpen: false,
      row: {
        asc_data: {}
      },
      validation: {},
    }
  },
  computed: {
  },
  methods: {
    submitContent(){
      this.$refs.VForm.validate().then(result => {
        if(!result) return

        this.swalLoading()

        Gen.apiRest("/do/"+this.modulePage, {
          data: {
            type: 'submit',
            ...this.row
          }
        }).then(res => {
          this.$swal({
            icon: 'success',
            title: res.data.message
          })
          this.apiGet()
        })
      })
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
  }
}
</script>
<style scoped>
.sect-heading__line {
  display: inline-block;
  width: 180px;
  height: 12px;
  border-radius: 20px;
  position: relative;
}
.sect-heading__line::before {
  content: '';
  position: absolute;
  top: 0;
  height: 60%;
  left: 60px;
  right: 60px;
  border-radius: 10px;
  background-color: #39768e;
}
</style>